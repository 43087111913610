.voice-recorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  background-color: #f0f0f0;
  text-align: center;
}

.voice-recorder button,
.custom-file-upload {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2em;
  background-color: #22A39F;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.voice-recorder button:hover,
.custom-file-upload:hover {
  background-color: #73BBC9;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.recording-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
}
