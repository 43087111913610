.text-sender {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    text-align: center;
    padding: 20px 0;
}

.text-sender .input-area {
    width: 80%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.text-sender button,
.text-sender input {
    padding: 10px 20px;
    font-size: 1.2em;
    text-align: center;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.text-sender button {
    background-color: #22A39F;
    color: white;
    cursor: pointer;
}

.text-sender button:hover {
    background-color: #73BBC9;
}

.text-sender input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
}

.text-sender pre {
    margin-top: 30px;
    padding: 20px;
    width: 90%;
    font-size: 1.4em;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    text-align: center;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.text-sender input::placeholder {
    text-align: center;
}

.text-sender .results {
    margin-top: 20px;
    width: 90%;
}

.text-sender .results h2 {
    font-size: 2em;
}
