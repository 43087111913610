.app {
  text-align: center;
}

.app-header {
  background-color: #222222;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-header h1 {
  font-size: 2em;
}

.app-header nav {
  display: flex;
  justify-content: space-between;
  width: 20%;
  margin-top: 20px;
}

.app-header nav a {
  color: #22A39F;
  text-decoration: none;
  padding: 0 10px;
}

.app-header nav a:hover {
  color: #73BBC9;
}
